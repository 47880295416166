import React from "react";
import { Link as ScrollLink } from "react-scroll";

const Footer = () => {
  const { PUBLIC_URL } = process.env;

  return (
    <footer className="footer has-background-white">
      <div className="container">
        <div className="columns">
          <div className="column is-4 has-text-centered is-hidden-tablet">
            <ScrollLink
              className="title is-4"
              to="top"
              spy={true}
              smooth={true}
            >
              <img
                src={`${PUBLIC_URL}/assets/logo-new.png`}
                alt="AllyTips Logo"
                width="40%"
              />
            </ScrollLink>
          </div>
          <div className="column is-4">
            <div className="level">
              <a
                className="level-item has-text-black"
                href="https://bit.ly/AllyTipsChrome"
              >
                Install for Chrome
              </a>
              <a
                className="level-item has-text-black"
                href="https://bit.ly/AllyTipsEdge"
              >
                Install for Edge
              </a>
            </div>
          </div>
          <div className="column is-4 has-text-centered is-hidden-mobile">
            <ScrollLink
              className="title is-4"
              to="top"
              spy={true}
              smooth={true}
            >
              <img
                src={`${PUBLIC_URL}/assets/logo-new.png`}
                alt="AllyTips Logo"
                width="100px"
              />
            </ScrollLink>
          </div>
          <div className="column is-4 has-text-right">
            <div className="level">
              <a
                className="level-item has-text-black"
                href="https://bit.ly/suggest-allytip"
              >
                Suggest an AllyTip
              </a>
              <ScrollLink
                className="level-item has-text-black"
                to="top"
                spy={true}
                smooth={true}
              >
                Back to Top
              </ScrollLink>
            </div>
          </div>
        </div>
        <p className="subtitle has-text-centered is-6">
          © 2020 Olaf Wrieden. All right reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
