import React from "react";
import { Element } from "react-scroll";

const Hero = () => {
  const { PUBLIC_URL } = process.env;

  return (
    <Element name="hero">
      <section className="hero is-white is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns  is-vcentered reverse-columns">
              <div
                className="column
        is-10-mobile is-offset-1-mobile
        is-10-tablet is-offset-1-tablet
        is-5-desktop is-offset-1-desktop
        is-5-widescreen is-offset-1-widescreen
        is-5-fullhd is-offset-1-fullhd"
                data-aos="fade-down"
              >
                <h1 className="title is-1 mb-6">
                  Become a better ally to all with simple AllyTips
                </h1>
                <h2 className="subtitle has-text-grey is-4 has-text-weight-normal is-family-sans-serif">
                  A Google Chrome and Microsoft Edge browser extension that
                  empowers you to put diversity and inclusion into action.
                </h2>
                <div className="buttons">
                  <a
                    className="button is-warning"
                    href="https://bit.ly/AllyTipsChrome"
                  >
                    <span>
                      <i className="fab fa-chrome mr-2"></i> Get for Chrome
                    </span>
                  </a>
                  <a className="button" href="https://bit.ly/AllyTipsEdge">
                    <span>
                      <i className="fab fa-edge mr-2"></i> Get for Edge
                    </span>
                  </a>
                </div>
              </div>
              <div
                data-aos="fade-right"
                className="column
        is-10-mobile is-offset-1-mobile
        is-10-tablet is-offset-1-tablet
        is-4-desktop is-offset-1-desktop
        is-4-widescreen is-offset-1-widescreen
        is-4-fullhd is-offset-1-fullhd"
              >
                <figure className="image">
                  <img
                    src={`${PUBLIC_URL}/assets/landing-image.png`}
                    alt="Screenshot of extension window"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
};

export default Hero;
