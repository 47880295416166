import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Element, Link as ScrollLink } from "react-scroll";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { PUBLIC_URL } = process.env;

  return (
    <Element name="top">
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <img
                src={`${PUBLIC_URL}/assets/logo-new.png`}
                alt="AllyTips Logo"
                onClick={() => setIsOpen(false)}
              />
            </Link>

            <div
              className={`navbar-burger burger ${isOpen ? "is-active" : ""}`}
              data-target="navbar"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div
            id="navbar"
            className={`navbar-menu ${isOpen ? "is-active" : ""}`}
          >
            <div className="navbar-end">
              <ScrollLink
                className="navbar-item"
                to="description"
                spy={true}
                smooth={true}
                offset={-250}
                onClick={() => setIsOpen(false)}
              >
                Why AllyTips
              </ScrollLink>
              <ScrollLink
                className="navbar-item"
                to="how-it-works"
                spy={true}
                smooth={true}
                onClick={() => setIsOpen(false)}
              >
                How it Works
              </ScrollLink>
              <a
                className="navbar-item"
                href="https://bit.ly/suggest-allytip"
                onClick={() => setIsOpen(false)}
              >
                Suggest
              </a>
            </div>
          </div>
        </div>
      </nav>
    </Element>
  );
};

export default Navigation;
