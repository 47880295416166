import React from "react";
import { Element } from "react-scroll";

const Description = () => (
  <Element name="description" className="hero has-background-white-bis pt-6 pb-6">
    <section className="section columns">
      <div className="container has-text-centered column is-4">
        <h2 className="title is-spaced">Why AllyTips?</h2>
        <p className="subtitle has-text-grey is-5 has-text-weight-normal is-family-sans-serif">
          Diversity and Inclusion have become a key focus for many businesses
          today. Allyship, equality, kindness, and related terms are all around
          us. The big question is: <i className="has-text-weight-semibold">How can we put it into action?</i>
        </p>
      </div>
      <div className="container has-text-centered column is-5">
        <h2 className="title is-spaced">Our Promise</h2>
        <p className="subtitle has-text-grey is-5 has-text-weight-normal is-family-sans-serif">
          AllyTips is designed to help you become a better Ally-to-All with{" "}
          <span className="has-text-weight-semibold">bite-sized</span> daily
          tips and{" "}
          <span className="has-text-weight-semibold">practical steps</span> to
          create inclusive workplaces. Complete one #AllyTip a day and unlock
          small milestones, or help us build a{" "}
          <span className="has-text-weight-semibold">globally-relevant</span>{" "}
          knowledge base by sharing your tips with others.
        </p>
      </div>
    </section>
  </Element>
);

export default Description;
