import React from "react";
import { Element } from "react-scroll";
const { PUBLIC_URL } = process.env;

const HowItWorks = () => (
  <Element name="how-it-works" className="mt-6 pb-6">
    <section className="section">
      <div className="container has-text-centered py-4">
        <h2 className="title mb-6">How It Works</h2>
        <div className="columns is-multiline is-centered">
          <div className="column is-8 is-3-widescreen">
            <div className="block">
              <img
                src={`${PUBLIC_URL}/assets/intro-puzzle.png`}
                width="50%"
                alt="Install Extension icon"
              />
            </div>
            <h4 className="title pb-3">
              <small className="number">1</small> Install Extension
            </h4>
            <p className="subtitle has-text-grey is-5 has-text-weight-normal is-family-sans-serif">
              AllyTips sits quietly in your Google Chrome or Microsoft Edge
              browser.{" "}
              <a className="has-text-dark" href="https://bit.ly/AllyTipsChrome">
                Install it now
              </a>
              .
            </p>
          </div>
          <div className="column is-8 is-3-widescreen">
            <div className="block">
              <img
                src={`${PUBLIC_URL}/assets/intro-lightbulb.png`}
                width="50%"
                alt="Learn Tips icon"
              />
            </div>
            <h4 className="title pb-3">
              <small className="number">2</small> Learn Tips
            </h4>
            <p className="subtitle has-text-grey is-5 has-text-weight-normal is-family-sans-serif">
              Click the extension icon for a new tip every day and incorporate
              our practical tips into your daily interactions.
            </p>
          </div>
          <div className="column is-8 is-3-widescreen">
            <div className="block">
              <img
                src={`${PUBLIC_URL}/assets/intro-click.png`}
                width="50%"
                alt="Collect Points Icon"
              />
            </div>
            <h4 className="title pb-3">
              <small className="number">3</small> Collect Points
            </h4>
            <p className="subtitle has-text-grey is-5 has-text-weight-normal is-family-sans-serif">
              Every time you mark a tip as completed you earn a point. Sometimes
              a tip may reappear to remind you.
            </p>
          </div>
          <div className="column is-8 is-3-widescreen">
            <div className="block">
              <img
                src={`${PUBLIC_URL}/assets/intro-share.png`}
                width="50%"
                alt="Share AllyTips Icon"
              />
            </div>
            <h4 className="title pb-3">
              <small className="number">4</small> Share #AllyTips
            </h4>
            <p className="subtitle has-text-grey is-5 has-text-weight-normal is-family-sans-serif">
              We have created perfectly neat #AllyTip cards to share via your
              professional social media.
            </p>
          </div>
        </div>
        <div className="buttons is-centered mt-6">
          <a className="button is-warning" href="https://bit.ly/AllyTipsChrome">
            <span>
              <i className="fab fa-chrome mr-2"></i> Install AllyTips
            </span>
          </a>
        </div>
      </div>
    </section>
  </Element>
);

export default HowItWorks;
