import React from "react";
import { BrowserRouter } from "react-router-dom";
import Navigation from "./Navigation";
import Router from "./Router";

const App = () => (
  <BrowserRouter basename="/">
    <Navigation />
    <Router />
  </BrowserRouter>
);

export default App;
