import React from "react";
import Description from "./components/Description";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import HowItWorks from "./components/HowItWorks";

const Landing = () => {
  return (
    <>
      <Hero />
      <Description />
      <HowItWorks />
      <Footer />
    </>
  );
};

export default Landing;
